body {
  font-family: Arial, sans-serif;
  background-color: #f8f5e6;
  margin: 0;
  padding: 0;
 /* display: flex;*/
  justify-content: center;
  align-items: center;
  height: 100vh;
  @keyframes flashing {
    0% { color: red; }
    50% { color: blue; }
    100% { color: green; }
  }
  
  .flashing-text {
    font-weight: bold;
    font-size: 1.2em;
    animation: flashing 1.2s infinite alternate;
  }
  
}

.home {
  text-align: center;
  padding: 40px;
  background-color: #fff8dc; /* Fondo color trigo */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave para el div */
  margin: 50px auto; /* Centra el div horizontalmente */
  max-width: 800px; /* Limita el ancho del contenedor */
  @keyframes flashing {
    0% { color: red; }
    50% { color: blue; }
    100% { color: green; }
  }
  
  .flashing-text {
    font-weight: bold;
    font-size: 1.2em;
    animation: flashing 1.2s infinite alternate;
  }
  
}

.homepage-body {
  font-family: Arial, sans-serif;
  background-color: #f8f5e6;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.homepage {
  text-align: center;
  padding: 40px;
  background-color: #fff8dc; /* Fondo color trigo */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave para el div */
  margin: 50px auto; /* Centra el div horizontalmente */
  max-width: 800px; /* Limita el ancho del contenedor */
}

.homepage h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #8b4513; /* Marrón oscuro para el título */
}

.homepage p {
  font-size: 1.1em;
  margin-bottom: 40px;
  color: #8b4513; /* El mismo tono de marrón para el párrafo */
}

.homepage-imagen-ajustada {
  width: 100%;
  max-width: 400px;
  display: block;
  margin: 0 auto; /* Centra horizontalmente */
}

.homepage-boton-login {
  margin-top: 20px; /* Espacio superior */
  padding: 10px 20px;
  background-color: #007bff; /* Color de fondo */
  color: #fff; /* Color de texto */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.homepage-boton-login:hover {
  background-color: #0056b3;
  opacity: 0.8; /* Efecto visual al hacer hover */
}

.homepage-boton-registrar {
  margin-top: 20px; /* Espacio superior */
  padding: 10px 20px;
  background-color: #28a745; /* Color de fondo */
  color: #fff; /* Color de texto */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.homepage-boton-registrar:hover {
  background-color: #1e7e34;
  opacity: 0.8; /* Efecto visual al hacer hover */
}

@media (max-width: 600px) {
  .homepage {
    padding: 20px;
    margin: 20px;
  }
}

@keyframes flashing {
  0% { color: red; }
  50% { color: blue; }
  100% { color: green; }
}

.flashing-text {
  font-weight: bold;
  font-size: 1.2em;
  animation: flashing 1.2s infinite alternate;
}


.home h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #8b4513; /* Marrón oscuro para el título */
}

.home p {
  font-size: 1.1em;
  margin-bottom: 40px;
  color: #8b4513; /* El mismo tono de marrón para el párrafo */
}


.login {
  background-color: #fff8dc; /* Fondo color trigo más oscuro */
  padding: 40px;
  max-width: 400px;
  margin: 100px auto; /* Centra el formulario en la pantalla */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.login h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #8b4513; /* Marrón oscuro para el título */
  text-align: center;
}

.register {
  background-color: #fff8dc; /* Fondo color trigo más oscuro */
  padding: 40px;
  max-width: 600px;
  margin: 100px auto; /* Centra el formulario en la pantalla */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.register h1 {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #8b4513; /* Marrón oscuro para el título */
  text-align: center;
}

.container {
  width: 90%;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff8dc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
}

h1 {
  text-align: center;
  color: #8b4513;
}

form {
  display: flex;
  flex-direction: column;
}

form > div {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #8b4513;
}

input[type="text"],
input[type="number"],
input[type="file"],
select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #deb887;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type="file"] {
  padding: 3px;
}

input[type="checkbox"] {
  width: auto;
  margin-right: 5px;
}

.error {
  color: red;
  font-size: 0.875em;
}

button {
  padding: 10px 15px;
  color: #fff;
  background-color: #d2b48c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

button:hover {
  background-color: #c0a16b;
}

button[type="button"] {
  background-color: #f4a460;
}

button[type="button"]:hover {
  background-color: #e38d5c;
}

h2 {
  font-size: 1.25em;
  color: #8b4513;
}

fieldset {
  border: 1px solid #deb887;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

legend {
  font-size: 1.25em;
  font-weight: bold;
  color: #8b4513;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.flex-container > div {
  flex: 1 1 45%;
  min-width: 200px;
}

@media (max-width: 600px) {
  .container {
    width: 100%;
    margin: 0 10px;
  }

  .flex-container {
    flex-direction: column;
  }

  .flex-container > div {
    width: 100%;
  }


  @keyframes flashing {
    0% { color: red; }
    50% { color: blue; }
    100% { color: green; }
  }
  
  .flashing-text {
    font-weight: bold;
    font-size: 1.2em;
    animation: flashing 1.2s infinite alternate;
  }
  
}