html, body {
  scroll-behavior: smooth;
  scroll-margin-top: 20px; /* Espacio cuando haces scroll hacia arriba */
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.container {
  width: 100%; /* Siempre ocupa el ancho disponible */
  max-width: 100%; /* Previene desbordamiento horizontal */
  padding: 10px; /* Reduce el espacio interno */
  margin: 0 auto; /* Centra el contenedor */
  box-sizing: border-box; /* Asegura que padding no desborde */
}

label {
  display: block;
  font-weight: bold;
  color: #333333;
  margin: 10px 0; /* Simplificado */
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="file"],
select,
textarea {
  width: 100%;
  max-width: 100%; /* Asegúrate de que nunca excedan el ancho */
  padding: 8px; /* Mantén un padding moderado */
  margin: 5px 0; /* Reduce el margen */
  box-sizing: border-box; /* Previene desbordamientos */
  font-size: 14px; /* Ajusta el tamaño de texto */
}


input[type="file"] {
  margin: 10px 0;
}

button {
  width: 100%; /* Los botones ocupan todo el ancho en pantallas pequeñas */
  max-width: 100%;
  padding: 10px;
  font-size: 14px;
  margin-top: 10px;
}


button:hover {
  background-color: #c0a16b;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.talles-table {
  overflow-x: auto;
  white-space: nowrap;
}

/* Cambios responsive */
@media (max-width: 768px) {
  .container {
    margin: 10px;
    padding: 15px;
  }
}

@media (max-width: 600px) {
  .container {
    margin: 10px auto;
    padding: 15px;
  }

  .talles-table {
    display: block; /* Permite scroll horizontal si es necesario */
    width: 100%; /* Ocupa todo el ancho disponible */
    overflow-x: auto;
  }
  
  .talles-table th,
  .talles-table td {
    font-size: 12px; /* Reduce el tamaño de las celdas */
    padding: 5px; /* Reduce el espacio interno */
  }
  

  label, input, select, button {
    font-size: 0.9em;
  }
}
@media (max-width: 590px) {
  .container {
    padding: 5px; /* Reduce aún más el espacio interno */
  }

  input, select, button {
    font-size: 12px; /* Reduce el tamaño del texto */
    padding: 6px; /* Reduce el padding */
  }

  .talles-table th,
  .talles-table td {
    font-size: 10px; /* Ajusta el texto de las tablas */
    padding: 4px;
  }
}
.login-container {
  width: 100%;
  max-width: 400px; /* Ancho máximo del formulario */
  margin: 20px auto; /* Centra el formulario */
  padding: 20px; /* Espaciado interno */
  background-color: #fff8dc; /* Fondo color trigo */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
  border-radius: 10px; /* Bordes redondeados */
  text-align: center; /* Centrar el contenido */
}
/* Contenedor principal del formulario */
.login {
  width: 100%;
  max-width: 400px; /* Limita el ancho máximo a 400px */
  margin: 20px auto; /* Centra el formulario horizontalmente */
  padding: 20px; /* Espaciado interno */
  background-color: #fff8dc; /* Fondo color trigo */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
  border-radius: 10px; /* Bordes redondeados */
  text-align: center; /* Centra el texto */
}

/* Título del formulario */
.login h2 {
  font-size: 1.8em; /* Tamaño del título */
  color: #8b4513; /* Marrón oscuro */
  margin-bottom: 20px; /* Espaciado debajo del título */
}

/* Estilo para las etiquetas */
.login label {
  display: block; /* Asegura que esté en su propia línea */
  font-weight: bold;
  color: #8b4513; /* Marrón oscuro */
  text-align: left; /* Alinea a la izquierda */
  margin-bottom: 5px;
}

/* Estilo para los campos de entrada */
.login input {
  width: 100%; /* Ocupa todo el ancho disponible */
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #deb887; /* Borde beige */
  border-radius: 5px; /* Bordes redondeados */
  box-sizing: border-box; /* Incluye el padding en el ancho */
  font-size: 14px; /* Tamaño de texto */
}

/* Mensajes de error */
.login div > div {
  color: red; /* Color del texto del error */
  font-size: 0.85em; /* Tamaño de fuente reducido */
  margin-top: -10px; /* Reduce el espacio entre el error y el campo */
  margin-bottom: 10px; /* Espaciado debajo del error */
  text-align: left;
}

/* Botón de Login */
.login button {
  width: 100%; /* El botón ocupa todo el ancho */
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #d2b48c; /* Color beige */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.login button:hover {
  background-color: #c0a16b; /* Color más oscuro al pasar el mouse */
}

.login button:disabled {
  background-color: #e0c8a2; /* Color más claro cuando está deshabilitado */
  cursor: not-allowed;
}
@media (max-width: 600px) {
  .login {
    padding: 15px; /* Reduce el padding interno */
    margin: 10px; /* Reduce el margen externo */
  }

  .login h2 {
    font-size: 1.5em; /* Reduce el tamaño del título */
  }

  .login input {
    font-size: 12px; /* Reduce el tamaño del texto en los inputs */
    padding: 8px; /* Reduce el padding */
  }

  .login button {
    font-size: 14px; /* Reduce el tamaño del texto en el botón */
    padding: 8px;
  }
}
