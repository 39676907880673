.main-loading {
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: #FFF7;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-loading p {
    text-align: center;
    font-size: 15px;
}
/* HTML: <div class="loader"></div> */
.loader {
    font-weight: bold;
    font-family: monospace;
    font-size: 30px;
    display: inline-grid;
    overflow: hidden;
  }
  .loader:before,
  .loader:after {
    content: "Cargando...";
    grid-area: 1/1;
    clip-path: inset(0 -200% 50%);
    text-shadow: -10ch 0 0;
    animation: l12 1s infinite;
  }
  .loader:after {
    clip-path: inset(50% -200% 0%);
    text-shadow: 10ch 0 0;
    --s:-1;
  }
  @keyframes l12 {50%,100%{transform: translateX(calc(var(--s,1)*100%));}}